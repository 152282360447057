.App {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}


.headerTitle {
  text-decoration: none;
  color:white;
  font-size: 1.5em;
}


.questionBlock .MuiCard-root {
  margin-top: 2em;
}

.scoreBoard {
  font-size: 4vh;

}

.small-text {
  font-size: 0.9em;
  text-align: center;
}

.welcome-phrase {
  font-weight: bold;
  text-align: center;
}

.MuiBox-root {
  padding-top: 5px !important;
}

.centered-text {
  text-align: center;
}

.game-container {
  color: black;
  font-family: 'Noto Sans JP', sans-serif;
}

.landingForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.questionContainer {
  width: 300px;
  align-items: center;
  background-color: #61dafb;
}

#header {
  background-color: #880a0a;
  min-height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.manage-phase-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.category-card {
  margin-bottom: 1em;
  box-shadow: 3px 5px #880a0a;
}


ol.choose-list, ul.choose-list {
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

ul.possible-answers {
  margin: 0px;
  padding: 0px;
  list-style: inside;
}

ol.previous-attempts li {
  margin-bottom: 1em;
}

body .MuiFormControl-root {
  display: block;
  margin-bottom: 2em;
}

.MuiPaper-outlined {
  margin-bottom: 2em;
}
